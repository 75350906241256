/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-07-23",
    versionChannel: "nightly",
    buildDate: "2024-07-23T00:05:24.982Z",
    commitHash: "e9c610816b04d9cc8d21ebee2c6aab5e8995a8eb",
};
